import { PrivilegeName } from '@tremaze/shared/permission/types';
import { map, Observable } from 'rxjs';
import { Department } from '@tremaze/shared/feature/department/types';
import { DefaultDataSourceMethods } from '@tremaze/shared/util-http';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { Division } from '@tremaze/shared/feature/division/types';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Pagination } from '@tremaze/shared/models';
import { User, UserTypeName } from '@tremaze/shared/feature/user/types';
import {
  AssignmentAction,
  AssignmentEntityDataSource,
  AssignmentTarget,
  PaginatedSuggestionConfig,
  SuggestionConfig,
} from './shared-feature-assignment-entity-data-access';
import { Injectable } from '@angular/core';

const _pageSize = 50;

@Injectable()
export class RemoteAssignmentEntityDataSource
  implements AssignmentEntityDataSource
{
  private _getDepartmentsByPrivilege(
    privileges: PrivilegeName[],
    filterValue?: string,
  ): Observable<Department[]> {
    return DefaultDataSourceMethods.getPaginated(
      this._http,
      'cc/entityPermissions/departments',
      Department.deserialize,
      {
        q: {
          perms: privileges,
        },
        filter: {
          pageSize: _pageSize,
          filterValue,
          filterFields: ['NAME'],
          sort: 'name',
          sortDirection: 'asc',
        },
      },
    ).pipe(map((response) => response.content));
  }

  getDepartments(
    target: AssignmentTarget,
    action: AssignmentAction,
    filter?: string,
  ): Observable<Department[]> {
    let privileges: PrivilegeName[] = [];
    switch (action) {
      case 'write':
        switch (target) {
          case 'event':
            privileges = ['EVENT_WRITE', 'EVENT_DELETE_DEPARTMENT'];
            break;
          case 'information':
            privileges = ['INFORMATION_WRITE', 'INFORMATION_DELETE_DEPARTMENT'];
            break;
        }
        break;
      case 'delete':
        switch (target) {
          case 'event':
            privileges = ['EVENT_DELETE', 'EVENT_DELETE_DEPARTMENT'];
            break;
          case 'information':
            privileges = [
              'INFORMATION_DELETE',
              'INFORMATION_DELETE_DEPARTMENT',
            ];
            break;
        }
    }
    return this._getDepartmentsByPrivilege(privileges, filter);
  }

  private _getInstitutionsByPrivilege(
    privileges: PrivilegeName[],
    filterValue?: string,
  ): Observable<Institution[]> {
    return DefaultDataSourceMethods.getPaginated(
      this._http,
      'cc/entityPermissions/institutions',
      Institution.deserialize,
      {
        q: {
          perms: privileges,
        },
        filter: {
          pageSize: _pageSize,
          filterValue,
          filterFields: ['NAME'],
          sort: 'name',
          sortDirection: 'asc',
        },
      },
    ).pipe(map((response) => response.content));
  }

  private _getInstitutionsFromSuggestionsAPI(
    action: 'DIVISION_MAPPING',
    config?: SuggestionConfig,
  ): Observable<Institution[]> {
    return this._http
      .get<unknown[]>('/v2/suggestions/institutions', {
        params: {
          suggestionAction: action,
          filterValue: config?.filterValue ? `%${config.filterValue}%` : '',
          filterFields: 'NAME',
        },
      })
      .pipe(
        map((response) =>
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          response.map((d) => Institution.deserialize(d)!).filter((d) => !!d),
        ),
      );
  }

  getInstitutions(
    target: AssignmentTarget,
    action: AssignmentAction,
    config?: SuggestionConfig,
  ): Observable<Institution[]> {
    if (action) {
      switch (target) {
        case 'division':
          return this._getInstitutionsFromSuggestionsAPI(
            'DIVISION_MAPPING',
            config,
          );
      }
    }

    let privileges: PrivilegeName[] = [];
    switch (action) {
      case 'write':
        switch (target) {
          case 'event':
            privileges = ['EVENT_WRITE'];
            break;
          case 'information':
            privileges = ['INFORMATION_WRITE'];
            break;
        }
        break;
      case 'delete':
        switch (target) {
          case 'event':
            privileges = ['EVENT_DELETE'];
            break;
          case 'information':
            privileges = ['INFORMATION_DELETE'];
            break;
        }
    }
    return this._getInstitutionsByPrivilege(privileges, config?.filterValue);
  }

  private _getDivisionsFromSuggestionsAPI(
    action: 'INST_WRITE' | 'INFORMATION_WRITE',
    config?: SuggestionConfig,
  ): Observable<Division[]> {
    return this._http
      .get<unknown[]>('/v2/suggestions/divisions', {
        params: {
          suggestionAction: action,
          filterValue: config?.filterValue ? `%${config.filterValue}%` : '',
        },
      })
      .pipe(
        map((response) =>
          response
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .map((d) => Division.deserialize(d)!)
            .filter((d) => !!d)
            .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? '')),
        ),
      );
  }

  getDivisions(
    target: Omit<AssignmentTarget, 'division'>,
    action: AssignmentAction,
    config?: SuggestionConfig,
  ): Observable<Division[]> {
    if (action === 'write') {
      switch (target) {
        case 'institution':
          return this._getDivisionsFromSuggestionsAPI('INST_WRITE', config);
        case 'information':
          return this._getDivisionsFromSuggestionsAPI(
            'INFORMATION_WRITE',
            config,
          );
      }
    }

    const queryParams = {
      filterValue: config?.filterValue ?? '',
      filterFields: 'NAME',
    };
    const params = new HttpParams({ fromObject: queryParams });
    return this._http.get<Pagination<any>>(`/divisions`, { params }).pipe(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      map((response) => response.content.map((d) => Division.deserialize(d)!)),
    );
  }

  private _getUsersByPrivilege(
    privileges: PrivilegeName[],
    filterValue?: string,
    userTypes?: UserTypeName[],
  ): Observable<User[]> {
    const q = {
      perms: privileges,
    } as any;
    if (userTypes) {
      q['userTypeIdentifier'] = userTypes;
    }
    return DefaultDataSourceMethods.getPaginated(
      this._http,
      'entityPermissions/users',
      User.deserialize,
      {
        q,
        filter: {
          pageSize: _pageSize,
          filterValue,
          filterFields: ['FIRST_NAME', 'LAST_NAME', 'USERNAME'],
          sort: 'lastName',
          sortDirection: 'asc',
        },
      },
    ).pipe(map((response) => response.content));
  }

  private _getUsersFromSuggestionsAPI(
    action: 'MANAGED_USERS' | 'DIVISION_USERS',
    config?: SuggestionConfig,
  ): Observable<User[]> {
    return this._http
      .get<unknown[]>('/v2/suggestions/users', {
        params: {
          suggestionAction: action,
          filterValue: config?.filterValue ?? '',
          filterFields: 'FIRST_NAME,LAST_NAME,USERNAME',
        },
      })
      .pipe(
        map((response) =>
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          response.map((d) => User.deserialize(d)!).filter((d) => !!d),
        ),
      );
  }

  private _getPaginatedUsersFromSuggestionsAPI(
    action: 'RESOURCE_VIEW_USERS',
    config: PaginatedSuggestionConfig & {
      userTypeIdentifiers?: UserTypeName[];
    } = {},
  ): Observable<Pagination<User>> {
    const params = new HttpParams({
      fromObject: configToQueryParamObject(
        action,
        {
          ...config,
          sort: 'lastName,asc',
          userTypeIdentifiers: config?.userTypeIdentifiers,
        },
        ['FIRST_NAME', 'LAST_NAME', 'USERNAME'],
      ),
    });
    return this._http
      .get('/v3/suggestions/users', { params })
      .pipe(
        map(
          (response) =>
            Pagination.deserialize(response, User.deserialize) as any,
        ),
      );
  }

  getUsers(
    target: AssignmentTarget,
    action: AssignmentAction,
    filter?: string,
    userTypes?: UserTypeName[],
  ): Observable<User[]> {
    let privileges: PrivilegeName[] = [];
    switch (action) {
      case 'write':
        switch (target) {
          case 'event':
            privileges = [
              'EVENT_WRITE',
              'EVENT_WRITE_REFERENCE_CLIENT',
              'EVENT_CLIENT_WRITE',
              'EVENT_WRITE_DEPARTMENT',
              'EVENT_EMPLOYEE_WRITE',
            ];
            break;
          case 'information':
            privileges = [
              'INFORMATION_WRITE',
              'INFORMATION_WRITE_REFERENCE_CLIENT',
              'INFORMATION_WRITE_DEPARTMENT',
              'INFORMATION_CLIENT_WRITE',
              'INFORMATION_EMPLOYEE_WRITE',
            ];
            break;
          case 'family':
            privileges = ['FAMILY_MANAGE', 'FAMILY_MANAGE_REFERENCE_CLIENT'];
            break;
        }
        break;
      case 'delete':
        switch (target) {
          case 'event':
            privileges = ['EVENT_DELETE', 'EVENT_DELETE_REFERENCE_CLIENT'];
            break;
          case 'information':
            privileges = [
              'INFORMATION_DELETE',
              'INFORMATION_DELETE_REFERENCE_CLIENT',
            ];
            break;
        }
    }
    return this._getUsersByPrivilege(privileges, filter, userTypes);
  }

  getEventResourceViewUserSuggestions(
    config?: PaginatedSuggestionConfig,
  ): Observable<Pagination<User>> {
    return this._getPaginatedUsersFromSuggestionsAPI('RESOURCE_VIEW_USERS', {
      ...config,
      userTypeIdentifiers: ['EMPLOYEE'],
    });
  }

  getManagedAccountsSuggestions(config?: SuggestionConfig): Observable<User[]> {
    return this._getUsersFromSuggestionsAPI('MANAGED_USERS', config);
  }

  constructor(private readonly _http: HttpClient) {}
}

function configToQueryParamObject(
  action: unknown,
  config?: (PaginatedSuggestionConfig | SuggestionConfig) & {
    sort?: string;
  } & {
    userTypeIdentifiers?: UserTypeName[];
  },
  filterFields?: string[],
): Record<string, string> {
  if (!config) {
    return {};
  }
  return {
    suggestionAction: action as string,
    userId: config.userId ?? '',
    filterValue: config.filterValue ? `%${config.filterValue}%` : '',
    sort: config.sort ?? '',
    page: (config as PaginatedSuggestionConfig).page?.toString() ?? '0',
    size:
      (config as PaginatedSuggestionConfig).pageSize?.toString() ??
      _pageSize.toString(),
    filterFields: filterFields?.join(',') ?? '',
    userTypeIdentifier: config.userTypeIdentifiers?.join(',') ?? '',
  };
}
