import {
  AfterViewChecked,
  Component,
  Inject,
  Input,
  ViewChild,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  AnnotationService,
  BookmarkViewService,
  FormDesignerService,
  FormFieldsService,
  LinkAnnotationService,
  MagnificationService,
  NavigationService,
  PageOrganizerService,
  PdfViewerComponent as pdfc,
  PrintService,
  TextSearchService,
  TextSelectionService,
  ThumbnailViewService,
  ToolbarService,
} from '@syncfusion/ej2-angular-pdfviewer';

@Component({
  selector: 'tremaze-pdf-viewer',
  template: `
    @if (proxyFixedDocumentUrl; as documentUrl) {
      <ejs-pdfviewer
        id="pdfviewer"
        [documentPath]="documentUrl"
        [resourceUrl]="resource"
        [enableDownload]="true"
        [downloadFileName]="documentName"
        serviceUrl="https://pdfviewer.tagea.app/api/pdfviewer"
        locale="de-DE"
        style="height:100%;display:block"
        [enablePrint]="true"
        [printScaleRatio]="5"
      >
      </ejs-pdfviewer>
    }
  `,
  styleUrl: './pdf-viewer.component.scss',
  providers: [
    LinkAnnotationService,
    BookmarkViewService,
    MagnificationService,
    ThumbnailViewService,
    ToolbarService,
    NavigationService,
    TextSearchService,
    TextSelectionService,
    PrintService,
    AnnotationService,
    FormDesignerService,
    FormFieldsService,
    PageOrganizerService,
  ],
})
export class PdfViewerComponent implements AfterViewChecked {
  @Input({ required: true }) documentUrl?: string;
  @Input({ required: true }) documentName = 'document.pdf';

  get proxyFixedDocumentUrl() {
    if (!this.documentUrl) {
      return;
    }
    return this.documentUrl.replace(
      'http://localhost:4200/api',
      'https://api.dev.cloud.tagea.app',
    );
  }

  private _initialized = false;

  @ViewChild(pdfc) pdfViewer?: pdfc;

  readonly resource = `${this.document.location.protocol}//${this.document.location.host}/assets/ej2-pdfviewer-lib`;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngAfterViewChecked() {
    if (!this._initialized && this.pdfViewer?.toolbar) {
      this._initialized = true;
      this.pdfViewer?.toolbar?.showToolbarItem(['SubmitForm'], false);
    }
  }

  async save() {
    if (!this.pdfViewer) {
      return;
    }
    const blob = await this.pdfViewer.saveAsBlob();
    return new Blob([blob], { type: 'application/pdf' });
  }

  print() {
    if (!this.pdfViewer) {
      return;
    }
    this.pdfViewer.print.print();
  }
}
